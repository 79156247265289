<template>
    <component :is="roleData === undefined ? 'div' : 'b-card'">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="roleData === undefined">
            <h4 class="alert-heading">Error fetching role data</h4>
            <div class="alert-body">
                No roles found with this role id. Check
                <b-link class="alert-link" :to="{ name: 'roles-list' }">
                    Role List
                </b-link>
                for other roles.

            </div>
        </b-alert>

            <!-- :userData="userData" -->
        <roles-permission v-if="roleData" :role-data="roleData"  :permission-data="permissionData" @refetch-data="refetchData"
            class="mt-2 pt-75" />

    </component>
</template>
  
<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import roleStoreModule from "../roleStoreModule";
import RolesPermission from "./RolesPermission.vue";
// import { getUserData} from "@/auth/utils";
// console.log('user',getUserData())
export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        RolesPermission,
    },
    setup() {
        const roleData = ref(null);
        const permissionData = ref(null);
        
        const APP_ROLE_STORE_MODULE_NAME = "app-role";

        const user = {
            id: 0
        }
        // const userData = ref(null)

        // Register module
        if (!store.hasModule(APP_ROLE_STORE_MODULE_NAME))
            store.registerModule(APP_ROLE_STORE_MODULE_NAME, roleStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(APP_ROLE_STORE_MODULE_NAME))
                store.unregisterModule(APP_ROLE_STORE_MODULE_NAME);
        });
        // onMounted(()=>{
        //     userData.value = getUserData();
        // })

        const refetchData = () => {
            store
                .dispatch("app-role/fetchRole", { id: router.currentRoute.params.id })
                .then((response) => {
                    console.log(response)
                    roleData.value = response.data.role;
                    permissionData.value = response.data.permissions;
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        roleData.value = undefined;
                        permissionData.value = undefined;
                    }
                });
        };

        refetchData()

        return {
            roleData,
            permissionData,
            refetchData,
            // userData
        };
    },
};
</script>
  
<style>

</style>
  